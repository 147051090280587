import { kpEndpoint, kpRequest, kpParams } from '@/axios'

export const GET_LEADAI_LIST = 'getLeadAiList'
export const FETCH_TAILORED_LEADS = 'fetchTailoredLeads'
export const FETCH_LEAD_DETAIL = 'fetchLeadDetail'
export const FETCH_LEAD_FILTERS = 'fetchLeadFilters'
export const UPDATE_APPLIED_FILTERS = 'updateFilterState'
export const UPDATE_SEARCH_TEXT = 'updateSearchText'
export const UPDATE_CURRENT_PAGE = 'updateCurrentPage'
export const UPDATE_SELECTED_COUNTRY = 'updateSelectedCountry'
export const UPDATE_SELECTED_STORE_TYPES = 'updateSelectedStoreTypes'
export const UPDATE_SELECTED_CATEGORIES = 'updateSelectedCategories'
export const UPDATE_SELECTED_RETAIL_PRICES = 'updateSelectedRetailPrices'
export const RESET_FILTERS = 'resetFilters'
export const GET_SAVED_LEADS = 'getSavedLeads'
export const SET_SAVED_LEADS_CONTACTS = 'setSavedLeadsContacts'
export const GET_ONE_LEAD = 'getOneLead'
export const SET_TAILORED_LEADS_FORM_DATA = 'setTailoredLeadsFormData'
export const SET_REQUEST_SENT_FLAG = 'setRequestSentFlag'
export const REVEAL_ONE_CONTACT = 'revealOneContact'
export const UPDATE_SAVED_LEAD = 'updateSavedLead'
export const FETCH_CURRENT_CREDIT_RATES = 'fetchCurrentCreditRates'
export const SET_CURRENT_CREDIT_RATES = 'setCurrentCreditRates'
export const FETCH_AVAILABLE_CREDITS = 'fetchAvailableCredits'
export const SET_AVAILABLE_CREDITS = 'setAvailableCredits'
export const FETCH_LEAD_POSITION = 'fetchLeadPosition'
export const UPDATE_LEAD_CONTACTS = 'updateLeadContacts'
export const POLL_REQUESTED_CONTACTS = 'pollRequestedContacts'

const state = {
  searchText: '',
  selectedCountry: [],
  selectedStoreTypes: [],
  selectedCategories: [],
  selectedRetailPrices: [],
  appliedFilters: {},
  tailoredLeadsFormData: null,
  currentPage: 1,
  availableCredits: 0,
  currentCreditRates: {
    search: 0,
    save: 0,
    reveal: 0,
  },
  savedLeadsContacts: [],
}

const actions = {
  async [GET_LEADAI_LIST](ctx, payload) {
    return await kpRequest({
      ...kpEndpoint.leadAI.getList,
      payload: {
        pageNumber: ctx.state.currentPage,
        pageSize: payload.pageSize,
        search: ctx.state.searchText,
        filters: ctx.state.appliedFilters,
      },
    })
  },
  async [FETCH_TAILORED_LEADS](ctx, payload) {
    return await kpRequest({
      ...kpEndpoint.leadAI.fetchTailoredLeads,
      payload,
    })
  },
  async [FETCH_LEAD_DETAIL](ctx, id) {
    return await kpRequest({ ...kpParams(kpEndpoint.leadAI.getDetail, id) })
  },
  async [FETCH_LEAD_FILTERS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.getFilters, payload })
  },
  async [GET_SAVED_LEADS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.getViewedLeads, payload })
  },
  async [GET_ONE_LEAD](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.getOneLead, payload })
  },
  async [REVEAL_ONE_CONTACT](ctx, { id, payload }) {
    return await kpRequest({ ...kpParams(kpEndpoint.leadAI.revealOneContact, id), payload })
  },
  async [FETCH_CURRENT_CREDIT_RATES]() {
    return await kpRequest({ ...kpEndpoint.leadAI.getCreditRates })
  },
  async [FETCH_AVAILABLE_CREDITS](ctx, { brandId }) {
    return await kpRequest({ ...kpParams(kpEndpoint.leadAI.fetchAvailableCredits, brandId) })
  },
  async [FETCH_LEAD_POSITION](ctx, { id, payload }) {
    return await kpRequest({ ...kpParams(kpEndpoint.leadAI.fetchLeadPosition, id), payload })
  },
  async [POLL_REQUESTED_CONTACTS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.pollRequestedContacts, payload })
  },
}

const mutations = {
  [UPDATE_APPLIED_FILTERS](state, filters = {}) {
    state.appliedFilters = filters
  },
  [UPDATE_SEARCH_TEXT](state, searchText = '') {
    state.searchText = searchText
  },
  [UPDATE_CURRENT_PAGE](state, page = 1) {
    state.currentPage = page
  },
  [UPDATE_SELECTED_COUNTRY](state, countries = []) {
    state.selectedCountry = countries
  },
  [UPDATE_SELECTED_STORE_TYPES](state, storeTypes = []) {
    state.selectedStoreTypes = storeTypes
  },
  [UPDATE_SELECTED_CATEGORIES](state, categories = []) {
    state.selectedCategories = categories
  },
  [UPDATE_SELECTED_RETAIL_PRICES](state, prices = []) {
    state.selectedRetailPrices = prices
  },
  [SET_TAILORED_LEADS_FORM_DATA](state, data = {}) {
    state.tailoredLeadsFormData = {
      ...state.tailoredLeadsFormData,
      ...data,
    }
  },
  [SET_REQUEST_SENT_FLAG](state, flag = false) {
    state.tailoredLeadsFormData = {
      ...state.tailoredLeadsFormData,
      isRequestSentUsingStoredData: flag,
    }
  },
  [UPDATE_SAVED_LEAD](state, domain) {
    state.tailoredLeadsFormData = {
      ...state.tailoredLeadsFormData,
      results: state.tailoredLeadsFormData?.results?.map(lead => {
        if (lead.domain === domain) {
          return {
            ...lead,
            viewed: true,
          }
        } else return lead
      }),
    }
  },
  [RESET_FILTERS](state) {
    state.searchText = ''
    state.selectedCountry = []
    state.selectedStoreTypes = []
    state.selectedCategories = []
    state.selectedRetailPrices = []
    state.appliedFilters = {}
    state.currentPage = 1
  },
  [SET_CURRENT_CREDIT_RATES](state, payload) {
    state.currentCreditRates = payload
  },
  [SET_AVAILABLE_CREDITS](state, credits) {
    state.availableCredits = credits
  },
  [SET_SAVED_LEADS_CONTACTS](state, newLeadContacts) {
    state.savedLeadsContacts = newLeadContacts
  },
  [UPDATE_LEAD_CONTACTS](state, payload) {
    state.savedLeadsContacts = state.savedLeadsContacts.map(contact => {
      if (contact.leadId === payload.leadId) {
        return {
          leadId: payload.leadId,
          contacts: payload.updatedContacts,
        }
      } else return contact
    })
  },

}

export default {
  state,
  actions,
  mutations,
}
