/* eslint-disable */

import store from '@/store';
import { getToken } from '@/store/jwt.service';
import axios from 'axios';
import Vue from 'vue';

import { API_METHODS, FILE_FORMATS } from './constants';
import { LOGOUT } from './store/modules/auth.module';
import {Settings} from "@/Settings";
const { POST, PUT, PATCH, GET, DELETE } = API_METHODS

let _kpApi = undefined;

const handleError = (err = {}) => {
  return new Promise(function (resolve, reject) {
    if(err.toJSON()?.message === 'Network Error') {
      reject('No internet connection. Please check your network settings.')
    }

    if (err.response && err.response.status !== 410) {
      Vue.prototype.$toast.error(err.response.data.message, {
        position: 'top',
      });
    }

    if (err.response.status === 401) {
      store.dispatch(LOGOUT);
    } else if (err.response.status === 410) {
      Vue.prototype.$toast.error('There is issues in the order items', {
        position: 'top',
      });
    }
    throw err;
  });
}

const kpApi = () => {
  if (!_kpApi) {

    _kpApi = axios.create({
      baseURL: Settings.getConfig("VUE_APP_BACKEND_URL"),
      withCredentials: true,
      headers: {
        lang: 'en',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    _kpApi.interceptors.request.use(
        function (config) {
          const token = getToken();
          if (token) config.headers.Authorization = `Bearer ${token}`;
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
    );

    _kpApi.interceptors.response.use(undefined, handleError);
  }
  return _kpApi;
}

Vue.prototype.$kpApi = kpApi;

// For Kingpin
const kpEndpoint = {
  user: {
    register: { url: 'v2/users/noAuth/register', method: POST },
    login: { url: 'v2/users/noAuth/login', method: POST },
    forgetPassword: { url: 'v2/users/noAuth/forget-password', method: POST },
    resetPassword: { url: 'v2/users/noAuth/reset-password', method: POST },
    sendLoginLink: { url: 'v2/users/noAuth/login', method: POST },
    connection: {
      getConnectionInfo: { url: '/users/connection-info', method: GET },
      sendRequestConnection: { url: '/users/request-connection', method: POST },
      connectionRequest: { url: 'users/connection-requests', method: GET },
      updateRequestConnection: {
        url: '/users/update-connection-request/:requestId',
        method: PUT,
      },
      export: { url: 'v2/users/direct/export', method: GET },
      import: { url: 'v2/users/direct/import', method: PUT },
      inviteRetailer: { url: 'v2/users/direct/invite-retailer', method: POST },
      inviteRetailerFromLeadAI: {
        url: 'v2/users/direct/invite-retailer/lead-ai',
        method: POST,
      },
      addRetailer: { url: 'v2/users/direct/add-retailer', method: PUT },
    },
    referrals: {
      get: { url: 'users/referrals', method: GET },
      sendMail: { url: 'users/referrals/send-email', method: POST },
      invite: { url: 'users/referrals', method: POST },
      validate: { url: 'users/referrals/validate', method: POST },
      updateWalletRule: { url: 'users/referrals/walletRule', method: PUT },
    },
    addNickName: { url: 'v2/users/nick-name', method: POST },
    updateNickName: { url: 'v2/users/nick-name', method: PUT },
    getSalesReps: { url: 'v2/users/entity/sales-reps', method: GET },
    scopes: {
      url: 'v2/users/entity/:userId/scope',
      method: GET,
      headers: {
        'Cache-policy': 'no-store, no-cache, must-revalidate, proxy-revalidate',
        Pragma: 'no-cache',
        Expires: 0,
      },
    },
  },
  catalog: {
    get: { url: 'v2/catalog/collections', method: GET },
    getById: { url: 'v2/catalog/collections/:collectionId', method: GET },
    create: { url: 'v2/catalog/collections', method: POST },
    update: { url: 'v2/catalog/collections/:collectionId', method: PATCH },
    downloadProductAvailabilityFile: {
      url: 'v2/catalog/collections/:collectionId/products-availability/export',
      method: GET,
    },
    getUpcomingdeadlines: {
      url: 'v2/catalog/collections/upcoming-deadlines',
      method: GET,
    },
    delete: { url: 'v2/catalog/collections/:collectionId', method: DELETE },
    pricebook: {
      get: { url: 'v2/catalog/collections/price-books', method: GET },
      create: {
        url: 'v2/catalog/collections/:collectionId/price-books',
        method: POST,
      },
      getByCollectionId: {
        url: 'v2/catalog/collections/:collectionId/price-books',
        method: GET,
      },
      import: {
        url: 'v2/catalog/collections/:collectionId/price-books/:priceBookId/import',
        method: PUT,
      },
      importMasterFile: {
        url: 'v2/catalog/collections/:collectionId/price-books/import',
        method: PUT,
      },
      export: {
        url: 'v2/catalog/collections/:collectionId/price-books/:priceBookId/export',
        method: GET,
      },
      exportMasterFile: {
        url: 'downloads/master-pricebook',
        method: POST,
      },
    },
    shopifySync: {
      url: 'v2/catalog/collections/:collectionId/sync',
      method: POST,
    },
    currencybook: {
      updateCurrencies: {
        url: 'v2/catalog/collections/:collectionId/update-currencies',
        method: PUT,
      },
      exportCurrencies: {
        url: 'v2/catalog/currencies/:collectionId/export/:currencyCode',
        method: GET,
      },
      exportMasterCurrencies: {
        url: 'v2/catalog/currencies/:collectionId/export',
        method: GET,
      },
      importCurrencies: {
        url: 'v2/catalog/currencies/:collectionId/import/:currencyCode',
        method: POST,
      },
      importMasterCurrencies: {
        url: 'v2/catalog/currencies/:collectionId/import',
        method: POST,
      },
    },
    products: {
      getProductsByIds: {
        url: 'v2/catalog/products/get-products-by-ids',
        method: POST,
      },
    },
  },
  profile: {
    getEntityDetails: { url: 'v2/entity/:role/:id', method: GET },
    updateEntityDetails: { url: 'v2/entity/:role/:id', method: PATCH },
    switchUser: { url: 'v2/users/token', method: POST },
    magicLinkLogin: { url: 'v2/users/magic-link-login', method: PUT },
    general: { url: 'v2/users/general', method: PUT },
    segmentation: { url: 'v2/users/segmentation', method: PUT },
    documents: { url: 'v2/users/documents', method: PUT },
    social: { url: 'v2/users/social', method: PUT },
    changePassword: { url: 'profile/change-password', method: PUT },
    closeToolkit: { url: 'v2/users/close-toolkit', method: PUT },
    onBoardCheckList: { url: 'v2/users/onboard-checklist', method: GET },
    addSuperUser: { url: 'profile/super-user', method: POST },
    updateSuperUser: { url: 'profile/super-user/:id', method: PUT },
    scheduleMeeting: { url: 'profile/schedule-email', method: POST },
    additionalUser: {
      add: { url: 'v2/users/additional-user', method: POST },
      update: { url: 'v2/users/additional-user', method: PUT },
      delete: {
        url: 'v2/users/additional-user/:additionalUserId',
        method: DELETE,
      },
    },
    // V2 apis
    get: {
      url: 'v2/users',
      method: GET,
      headers: {
        'Cache-policy': 'no-store, no-cache, must-revalidate, proxy-revalidate',
        Pragma: 'no-cache',
        Expires: 0,
      },
    },
    brandProfile: { url: 'v2/users/:brandId', method: GET },
    updateUser: { url: 'v2/users', method: PATCH },
    updatedUserById: { url: 'v2/users/:userId', method: PATCH },
    addStore: { url: 'v2/users/create-store', method: POST },
  },
  product: {
    list: { url: 'v2/catalog/products', method: GET },
    getById: { url: 'v2/catalog/products/:productId', method: GET },
    filter: { url: 'v2/catalog/products/filters', method: GET },
    downloadProductsFile: {
      url: 'v2/catalog/collections/:collectionId/products/export',
      method: GET,
    },
    browse: { url: 'v2/catalog/products/browse', method: GET },
    deleteProduct: { url: 'v2/catalog/products/:produtId', method: DELETE },
    relatedProducts: {
      url: 'v2/catalog/products/:productId/recommented-item',
      method: GET,
    },
    ids: { url: '/v2/catalog/products/product-ids', method: GET },
  },
  public: {
    wishlist: {
      get: { url: 'v2/public/wishlists/:publicToken', method: GET },
      filter: { url: 'v2/public/wishlist/:publicToken/filters', method: GET },
    },
  },
  wishlist: {
    create: { url: 'wishlists', method: POST },
    get: { url: 'wishlists', method: GET },
    put: { url: 'wishlists/:id', method: PUT },
    delete: { url: 'wishlists/:id', method: DELETE },
    removeProduct: {
      url: 'wishlists/:id/removeProduct/:productId',
      method: DELETE,
    },
    removeProducts: { url: 'wishlists/:id/removeProducts', method: POST },
    rename: { url: 'wishlists/:id/rename', method: PUT },
    download: { url: 'wishlists/:id/download/:fileFormat', method: GET },
    filter: { url: 'wishlists/filters', method: GET },
    share: { url: 'wishlists/:id/share', method: PUT },
    shareMultiple: { url: 'wishlists/share', method: POST },
    followUp: { url: 'wishlists/email-follow-up', method: POST },
    import: { url: 'wishlists/import', method: PUT },
    shareLink: {
      url: 'v2/catalog/wishlists/:id/create-public-link',
      method: POST,
    },
  },
  cart: {
    add: { url: 'v2/cart', method: PUT },
    get: { url: 'v2/cart', method: GET },
    deleteAll: { url: 'v2/cart/clear-all', method: PUT },
    deleteProduct: { url: 'v2/cart/products/:productId', method: DELETE },
    deleteProductByCartId: {
      url: 'v2/cart/:cartId/product/:productId',
      method: DELETE,
    },
    deleteProductsByCartId: { url: 'v2/cart/:cartId/products', method: DELETE },
    count: { url: 'v2/cart/count', method: GET },
    export: { url: 'v2/cart/export', method: GET },
    import: { url: 'v2/cart/import', method: PUT },
    delete: { url: 'v2/cart', method: DELETE },
    updateNotes: { url: 'v2/cart/notes', method: PUT },
  },
  order: {
    create: { url: 'orders', method: POST },
    createMultiple: { url: 'orders/multiple', method: POST },
    patch: { url: 'orders/:id', method: PATCH },
    get: { url: 'orders', method: GET },
    getById: { url: 'orders/:id', method: GET },
    cancel: { url: 'orders/:id/cancel', method: PUT },
    reject: { url: 'orders/:id/reject', method: PUT },
    modify: { url: 'orders/:id/products', method: PUT },
    approve: { url: 'orders/:id/confirm', method: PUT },
    download: { url: 'orders/download', method: GET },
    productionExport: {
      url: 'downloads/order/production-export',
      method: POST,
    },
    downloadProducts: { url: 'orders/:id/download/xlsx', method: GET },
    updateProductStatus: { url: 'orders/:id/products/status', method: PUT },
    import: { url: 'orders/:id/import', method: PUT },
    downloadInvoice: { url: 'orders/:id/download-invoice', method: GET },
    downloadImagesZip: { url: 'orders/:id/download/zip', method: GET },
    filterByFieldName: { url: 'orders/filter/:fieldName', method: GET },
    printOrderDetail: { url: 'orders/:id/print-detail', method: GET },
    addOrderProducts: { url: 'orders/:id/add-products', method: PUT },
  },
  shipment: {
    create: { url: 'shipments', method: POST },
    get: { url: 'shipments', method: GET },
    getById: { url: 'shipments/:shipmentId', method: GET },
    filterByEntityName: { url: 'shipments/filter/:nameType', method: GET },
    downloadInvoice: { url: 'shipments/:id/download-invoice', method: GET },
    downloadProducts: {
      url: 'shipments/:shipmentId/download/xlsx',
      method: GET,
    },
    update: { url: 'shipments/:shipmentId', method: PATCH },
    printShipmentDetail: { url: 'shipments/:id/print-detail', method: GET },
  },
  transaction: {
    get: { url: 'transactions', method: GET },
    download: { url: 'transactions/download', method: GET },
    pay: { url: 'transactions/:id/pay', method: POST },
    rewards: { url: 'transactions/rewards', method: GET },
    savings: { url: 'transactions/savings', method: GET },
  },
  wallet: {
    get: { url: 'walletRules', method: GET },
    update: { url: 'walletRules/:walletRuleId', method: PATCH },
  },
  common: {
    base: { url: 'common', method: GET },
    auth: { url: 'common/auth', method: GET },
    countries: { url: 'common/countries', method: GET },
  },
  guestUser: {
    login: { url: 'common/guest-user/login', method: POST },
  },
  files: {
    // All the files are uploaded using common action UPLOAD_DOCUMENT in store
    // check FILE_TYPE constant for file types
    fileUpload: { url: 'common/auth/files/:fileType', method: POST },
  },
  creditDebit: {
    creditApplication: {
      get: { url: 'creditAndDebit/credit-application', method: GET },
      post: { url: 'creditAndDebit/credit-application', method: POST },
      verify: { url: 'creditAndDebit/credit-application/verify', method: GET },
    },
  },
  notifications: {
    emailGroups: { url: 'notifications/email-groups', method: GET },
    get: { url: 'notifications', method: GET },
    post: { url: 'notifications', method: POST },
  },
  scheduleCollection: {
    subscribe: {
      url: 'users/scheduled-collections/:collectionScheduleId/subscribe',
      method: GET,
    },
    get: { url: 'users/scheduled-collections', method: GET },
    create: { url: 'users/scheduled-collections', method: POST },
    delete: { url: 'users/scheduled-collections/:id', method: DELETE },
  },
  integrations: {
    makeke: { url: 'v2/users/analytics', method: GET },
    salesAnalytics: { url: 'sales-insights', method: GET },
  },
  downloads: {
    getList: { url: 'downloads', method: GET },
    wishlist: { url: 'downloads/wishlist', method: POST },
    collection: { url: 'downloads/collection', method: POST },
    order: { url: 'downloads/order', method: POST },
    [FILE_FORMATS.XLSX]: { url: 'downloads/cart', method: POST },
    [FILE_FORMATS.PDF]: { url: 'downloads/cart/pdf', method: POST },
    [FILE_FORMATS.PPTX]: { url: 'downloads/cart/pptx', method: POST },
    cartExportMultiple: { url: 'downloads/cart/multiple', method: POST },
  },
  leadAI: {
    getList: { url: 'v2/entity/external-buyers/get-list', method: POST },
    getDetail: { url: 'v2/entity/external-buyers/:id', method: GET },
    getFilters: { url: '/v2/entity/external-buyers/get-filters', method: POST },
    getViewedLeads: {
      url: '/v2/entity/external-buyers/viewed-leads',
      method: POST,
    },
    fetchTailoredLeads: { url: '/v2/entity/leads-ai/get-list', method: POST },
    getOneLead: {
      url: '/v2/entity/leads-ai/get-one', method: POST
    },
    revealOneContact: {
      url: '/v2/entity/external-buyers/contacts/:id', method: POST
    },
    fetchLeadPosition: {
      url: '/v2/entity/external-buyers/viewed_lead_position/:id', method: POST,
    },
    getCreditRates: {
      url: 'v2/entity/leads-ai-credits/actions-rate', method: GET
    },
    fetchAvailableCredits: {
      url: 'v2/entity/leads-ai-credits/brand/:brandId', method: GET
    },
    pollRequestedContacts: {
      url: '/v2/entity/external-buyers/contacts', method: POST,
    }
  },
};
Vue.prototype.$kpEndpoint = kpEndpoint;

const kpParams = (endpointObj = {}, ...params) => {
  let iteration = 0;
  const urlWithParams = endpointObj?.url.replace(/:([a-zA-Z0-9]+)/g, (match, contents) => {
    if (params[iteration]) {
      contents = params[iteration];
      iteration++;
    }
    return contents;
  });
  return { url: urlWithParams, method: endpointObj?.method };
};

Vue.prototype.$kpParams = kpParams;

const kpRequest = (obj = {}) => {
  const requestPayload = {
    ...obj,
    data: obj?.payload || {},
  }

  if(obj?.headers) {
    requestPayload.headers = obj.headers
  }
  const kpApi2 = kpApi();
  return kpApi2(requestPayload)
};

Vue.prototype.$kpRequest = kpRequest;
//Trigger deployment
export { kpApi, kpEndpoint, kpParams, kpRequest };
