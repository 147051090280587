import store from '@/store'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import mixpanel from 'mixpanel-browser'
import { exProfileData } from '@/constants'
import { Settings } from '@/Settings'
import { apiToastError } from './toast'
import { formatObject } from './utils'

const { ROLES } = constants

function isGuest() {
  return store?.getters?.isGuest
}
function isAdmin() {
  return store?.getters?.isAdmin
}

const init = () => {
  if (Settings.mixpanelEnabled() && !isGuest()) mixpanel.init(Settings.mixpanelToken())
}

const identify = id => {
  if (Settings.mixpanelEnabled() && !isGuest()) mixpanel.identify(id)
}

const getProfileProps = profile => {
  const profileProps = {}

  if (profile.email) {
    profileProps.$email = profile.email
  }
  if (profile.entityName) {
    profileProps.$name = profile.entityName
  }
  if (profile.entityType) {
    profileProps.$entityType = profile.entityType
  }
  if (profile.role) {
    profileProps.$role = profile.role
  }

  return profileProps
}

const enrichProps = props => {
  const profile = store && store.getters && store.getters.profile
  const entityId = store && store.getters && store.getters.currentEntityId
  let updatedProps = { ...props }
  if (profile) {
    updatedProps = {
      ...updatedProps,
      ...getProfileProps(profile),
    }
  }

  if (entityId) {
    updatedProps = {
      ...updatedProps,
      $entityId: entityId,
    }
  }

  return updatedProps
}

const track = (name, props = {}) => {
  try {
    if (Settings.mixpanelEnabled() && !isGuest()) {
      let updatedProps = enrichProps(props)

      if (isAdmin()) {
        updatedProps = {
          ...updatedProps,
          via_magic_link: true,
        }
      }

      mixpanel.track(name, updatedProps)
    }
  } catch (err) {
    apiToastError(err)
  }
}

const people = {
  set: props => {
    if (Settings.mixpanelEnabled() && !isGuest() && !isAdmin()) mixpanel.people.set(props)
  },
}

const reset = () => {
  if (Settings.mixpanelEnabled() && !isGuest() && !isAdmin()) mixpanel.reset()
}

const setUserInAnalytics = profile => {
  if (profile?._id) {
    identify(profile._id)
    people.set(formatObject(profile, exProfileData))
  }
}

export default {
  init,
  setUserInAnalytics,
  identify,
  track,
  people,
  reset,
}

export const getSelectionOrWishlistMixpanelObj = (data = {}, isBrand = false, appendProps = {}, isHideNOP = false) => {
  const mixpanelData = {
    ...appendProps,
    collectionName: data.collectionName,
    createdBy: isBrand ? ROLES.BRAND : ROLES.RETAILER,
  }
  if (isBrand) {
    if (!isHideNOP) {
      mixpanelData.noOfProductsInSelection = data.count || 0
    }
    return mixpanelData
  }
  if (!isHideNOP) {
    mixpanelData.noOfProductsInWishlist = data.count || 0
  }
  return mixpanelData
}

export const getCollectionMixpanelObj = (data = {}, appendProps = {}) => ({
  ...appendProps,
  collectionName: data.collectionName || data.name,
  noOfProductsInCollection: data.count || data.productsCount,
})
