import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { Settings } from '@/Settings'
import store from '../../store'

function isBrand() {
  return store?.getters?.isBrand
}

export function onClickKnowledgeCenter(isDashboard = false) {
  const isBrandUser = isBrand()
  const knowledgeBaseUrl = isBrandUser ? Settings.getConfig('VUE_APP_KNOWLEDGE_BASE_BRAND') : Settings.getConfig('VUE_APP_KNOWLEDGE_BASE_RETAILER')
  const trackAction = isBrandUser ? constants.TRACKS.ACTIONS.BRAND_CLICKS_KNOWLEDGE_CENTER : constants.TRACKS.ACTIONS.RETAILER_CLICKS_KNOWLEDGE_CENTER
  const action = isDashboard ? `Dashboard page - ${trackAction}` : trackAction
  analytics.track(action)
  window.open(knowledgeBaseUrl, '_blank')
}

function getKingpinAcademyUrl() {
  const {
    isEntityAdmin, isSalesRep, isSalesManager,
   } = store.getters
  const VUE_APP_KINGPIN_ACADEMY_RETAILER_URL = Settings.getConfig('VUE_APP_KINGPIN_ACADEMY_RETAILER_URL')
  const VUE_APP_KINGPIN_ACADEMY_BRAND_EA_URL = Settings.getConfig('VUE_APP_KINGPIN_ACADEMY_BRAND_EA_URL')
  const VUE_APP_KINGPIN_ACADEMY_BRAND_SR_SM_URL = Settings.getConfig('VUE_APP_KINGPIN_ACADEMY_BRAND_SR_SM_URL')
  if (isBrand()) {
    if (isSalesRep || isSalesManager) {
      return VUE_APP_KINGPIN_ACADEMY_BRAND_SR_SM_URL
    }
    if (isEntityAdmin) {
      return VUE_APP_KINGPIN_ACADEMY_BRAND_EA_URL
    }
  }

  return VUE_APP_KINGPIN_ACADEMY_RETAILER_URL
}

function onClickKingpinAcademy() {
  const { profile } = store.getters
  const KingpinAcademyUrl = getKingpinAcademyUrl()
  if (KingpinAcademyUrl) {
    analytics.track(constants.TRACKS.ACTIONS.USER_CLICKS_KINGPIN_ACADEMY, { KingpinAcademyUrl, ...profile })
    window.open(KingpinAcademyUrl, '_blank')
  }
}

export const productDetailsRouteName = 'product-detail'
export const productDetailsWithWishlistRouteName = 'product-detail-with-wishlist'
export const productsListRouteName = 'collection/products'

export const routePaths = {
  wishlist: 'wishlist',
  selection: 'selection',
  downloads: 'downloads',
  salesAnalytics: 'sales-analytics',
}

const productDetailsRoute = {
  component: () => import('@/views/apps/product/detail/ProductDetails.vue'),
  meta: {
    pageTitle: 'Product Details',
    contentClass: 'ecommerce-application',
    breadcrumb: [
      {
        text: 'Explore',
        to: { name: 'explore' },
        acl: 'Retailer',
      },
      {
        text: 'Brand',
        dynamic: true,
        brandName: '...',
        parse: item => item.brandName,
        to: {
          name: 'brand/detail',
          params: { brandId: '$brandId' },
        },
      },
      {
        text: 'Collection',
        dynamic: true,
        collectionName: '...',
        parse: item => item.collectionName,
        to: {
          name: productsListRouteName,
          params: { collectionId: '$collectionId' },
        },
      },
      {
        text: 'Product $id',
        dynamic: true,
        productName: '...',
        parse(item) {
          return item.productName
        },
        active: true,
      },
    ],
    resource: 'Shop',
    action: 'read',
  },
}

export default [
  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/apps/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      resource: 'Dashboard',
      action: 'read',
    },
  },
  // collections
  {
    path: '/collections',
    name: 'collections',
    component: () => import('@/views/apps/collection/CollectionList.vue'),
    meta: {
      pageTitle: 'Collections',
      breadcrumb: [
        {
          text: 'Collections',
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  {
    path: '/collections/edit/:id?',
    name: 'collection/edit',
    component: () => import('@/views/apps/collection/CollectionEdit.vue'),
    meta: {
      pageTitle: 'Upload Collection',
      breadcrumb: [
        {
          text: 'Collections',
          to: { name: 'collections' },
        },
        {
          text: 'Create/Edit',
          dynamic: true,
          actionName: '...',
          parse(item) {
            return item.actionName
          },
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  // brands
  {
    path: '/explore',
    name: 'explore',
    component: () => import('@/views/apps/brand/explore/BrandExplore.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Explore',
      breadcrumb: [
        {
          text: 'Explore',
          active: true,
        },
      ],
      resource: 'Retailer',
      guestResource: 'Guest', // Specifying guestResource allows Guest User allow to access the page
      action: 'read',
    },
  },
  {
    path: '/discover-products',
    name: 'discover-products',
    component: () => import('@/views/apps/brand/explore/DiscoverProducts.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Explore',
      breadcrumb: [
        {
          text: 'Explore',
          active: true,
        },
      ],
      resource: 'Retailer',
      guestResource: 'Guest', // Specifying guestResource allows Guest User allow to access the page
      action: 'read',
    },
  },
  {
    path: '/explore/premiumbrands',
    name: 'premiumbrands',
    component: () => import('@/views/apps/brand/explore/BrandExplore.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Explore',
      breadcrumb: [
        {
          text: 'Explore',
          active: true,
        },
      ],
      resource: 'Retailer',
      action: 'read',
    },
  },
  {
    path: '/brand/:brandId',
    name: 'brand/detail',
    component: () => import('@/views/apps/brand/detail-v2/BrandDetail.vue'),
    meta: {
      pageTitle: 'Brand',
      breadcrumb: [
        {
          text: 'Explore',
          to: { name: 'explore' },
          acl: 'Retailer',
        },
        {
          text: 'Brand $brandId',
          dynamic: true,
          brandName: '...',
          parse: item => item.brandName,
          active: true,
        },
      ],
      resource: 'Profile',
      guestResource: 'Guest', // Specifying guestResource allows Guest User allow to access the page
      action: 'read',
    },
  },
  // products
  {
    path: '/products/:collectionId',
    name: productsListRouteName,
    component: () => import('@/views/apps/shop/Shop.vue'),
    props: true,
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Explore',
          to: { name: 'explore' },
          acl: 'Retailer',
        },
        {
          text: 'Collections / Brand',
          dynamic: true,
          routeName: '...',
          parse: item => item.routeName,
          to: { name: 'collections' },
        },
        {
          text: 'Collection $collectionId',
          dynamic: true,
          collectionName: '...',
          parse: item => item.collectionName,
          active: true,
        },
      ],
      resource: 'Shop',
      action: 'read',
    },
  },
  {
    path: '/product/:id/c/:collectionId',
    name: productDetailsRouteName,
    ...productDetailsRoute,
  },
  {
    path: '/product/:id/c/:collectionId/w/:wishlistId',
    name: productDetailsWithWishlistRouteName,
    ...productDetailsRoute,
  },
  // wishlist
  {
    path: `/${routePaths.wishlist}`,
    name: 'wishlist',
    props: true,
    component: () => import('@/views/apps/wishlist/Wishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      breadcrumb: [
        {
          text: 'Wishlist',
          to: { name: 'wishlist' },
          active: true,
        },
      ],
      resource: 'Retailer',
      action: 'read',
    },
  },
  {
    path: '/wishlist-products/:wishlistId',
    name: 'wishlist-products',
    props: true,
    component: () => import('@/views/apps/wishlist/WishlistProducts.vue'),
    meta: {
      pageTitle: 'Wishlist Products',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Wishlist',
          to: { name: 'wishlist' },
        },
        {
          text: 'wishlist name',
          dynamic: true,
          wishlistName: '...',
          parse: item => item.wishlistName,
          to: {
            name: 'wishlist',
            params: { wishlistId: '$wishlistId' },
          },
          active: true,
        },
      ],
      resource: 'Retailer',
      action: 'read',
    },
  },
  {
    path: `/${routePaths.selection}`,
    name: 'selection',
    props: true,
    component: () => import('@/views/apps/wishlist/Wishlist.vue'),
    meta: {
      pageTitle: 'Selection',
      breadcrumb: [
        {
          text: 'Selection',
          to: { name: 'selection' },
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  {
    path: `/${routePaths.salesAnalytics}`,
    name: 'sales-analytics',
    props: true,
    component: () => import('@/views/apps/sales-analytics/SalesAnalytics.vue'),
    meta: {
      pageTitle: 'Sales Insights',
      breadcrumb: [
        {
          text: 'Sales Insights',
          to: { name: 'sales-analytics' },
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  {
    path: '/selection-products/:wishlistId',
    name: 'selection-products',
    props: true,
    component: () => import('@/views/apps/wishlist/WishlistProducts.vue'),
    meta: {
      pageTitle: 'Selection Products',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Selection',
          to: { name: 'selection' },
        },
        {
          text: 'wishlist name',
          dynamic: true,
          wishlistName: '...',
          parse: item => item.wishlistName,
          to: {
            name: 'selection',
            params: { wishlistId: '$wishlistId' },
          },
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    props: true,
    component: () => import('@/views/apps/checkout-v2/CartPageMain.vue'),
    meta: {
      pageTitle: 'Carts',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Carts',
          active: true,
        },
      ],
      resource: 'Retailer',
      action: 'read',
    },
  },
  // order
  {
    path: '/orders',
    name: 'orders',
    props: true,
    component: () => import('@/views/apps/order/OrderList.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
      resource: 'Order',
      action: 'read',
    },
  },
  {
    path: '/shipments',
    name: 'shipments',
    props: true,
    component: () => import('@/views/apps/shipment/ShipmentList.vue'),
    meta: {
      pageTitle: 'Shipments',
      breadcrumb: [
        {
          text: 'Shipments',
          active: true,
        },
      ],
      resource: 'Shipment',
      action: 'read',
    },
  },
  {
    path: '/order/:orderId',
    name: 'order/detail',
    props: true,
    component: () => import('@/views/apps/order/Order.vue'),
    meta: {
      pageTitle: 'Orders',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Orders',
          to: { name: 'orders' },
        },
        {
          text: 'Detail',
          dynamic: true,
          orderName: '...',
          parse: item => item.orderName,
          active: true,
        },
      ],
      resource: 'Order',
      action: 'read',
    },
  },
  {
    path: '/shipment/:shipmentId',
    name: 'shipment/detail',
    props: true,
    component: () => import('@/views/apps/shipment/Shipment.vue'),
    meta: {
      pageTitle: 'Shipments',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Shipments',
          to: { name: 'shipments' },
        },
        {
          text: 'Detail',
          dynamic: true,
          orderName: '...',
          parse: item => item.orderName,
          active: true,
        },
      ],
      resource: 'Shipment',
      action: 'read',
    },
  },
  // transactions
  {
    path: '/wallet',
    props: true,
    component: () => import('@/views/apps/transaction/Transactions.vue'),
    meta: {
      pageTitle: 'Wallet',
      breadcrumb: [
        {
          text: 'Wallet',
          active: true,
        },
      ],
      action: 'read',
    },
    children: [
      {
        path: '',
        redirect: { name: 'transactions' },
      },
      {
        path: 'transactions',
        name: 'transactions',
        props: true,
        component: () => import('@/views/apps/transaction/TransactionList.vue'),
        meta: {
          pageTitle: 'Transactions',
          breadcrumb: [
            {
              text: 'Wallet',
              active: true,
            },
            {
              text: 'Transactions',
              active: true,
            },
          ],
          resource: 'Transactions',
          action: 'read',
        },
      },
      {
        path: 'savings',
        name: 'savings',
        props: true,
        component: () => import('@/views/apps/transaction/Savings.vue'),
        meta: {
          pageTitle: 'Savings',
          breadcrumb: [
            {
              text: 'Wallet',
              active: true,
            },
            {
              text: 'Savings',
              active: true,
            },
          ],
          resource: 'Brand',
          action: 'read',
        },
      },
      {
        path: 'rewards',
        name: 'rewards',
        props: true,
        component: () => import('@/views/apps/transaction/Rewards.vue'),
        meta: {
          pageTitle: 'Rewards',
          breadcrumb: [
            {
              text: 'Wallet',
              active: true,
            },
            {
              text: 'Rewards',
              active: true,
            },
          ],
          resource: 'Retailer',
          action: 'read',
        },
      },
    ],
  },
  // wallet
  {
    path: '/connections',
    name: 'connections',
    props: true,
    component: () => import('@/views/apps/wallet/ConnectionRequestList.vue'),
    meta: {
      pageTitle: 'Connection',
      breadcrumb: [
        {
          text: 'Connection',
          active: true,
        },
      ],
      resource: 'Wallet',
      action: 'read',
    },
  },
  // profile
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/apps/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
      resource: 'Profile',
      action: 'read',
    },
  },
  // messages
  {
    path: '/messages',
    name: 'messages',
    props: true,
    component: () => import('@/views/apps/notification/Notification.vue'),
    meta: {
      pageTitle: 'Messages',
      breadcrumb: [
        {
          text: 'Messages',
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  // referrals
  {
    path: '/referrals',
    name: 'referrals',
    props: true,
    component: () => import('@/views/apps/referral/Referrals.vue'),
    meta: {
      pageTitle: 'Referrals',
      breadcrumb: [
        {
          text: 'Referrals',
          active: true,
        },
      ],
      resource: 'Referrals',
      action: 'read',
    },
  },
  // Knowledge center
  {
    path: '/knowledge-center',
    name: 'knowledge-center',
    beforeEnter() {
      onClickKnowledgeCenter()
    },
    meta: {
      resource: 'Help',
      action: 'read',
    },
  },
    // Knowledge center
  {
    path: '/kingpin-academy',
    name: 'kingpin-academy',
    beforeEnter() {
      onClickKingpinAcademy()
    },
    meta: {
      resource: 'Help',
      action: 'read',
    },
  },
  // Analytics
  {
    path: '/analytics',
    name: 'analytics',
    props: true,
    component: () => import('@/views/apps/analytics/Analytics.vue'),
    meta: {
      pageTitle: 'analytics',
      breadcrumb: [
        {
          text: 'Analytics (beta)',
          to: { name: 'analytics' },
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  // Analytics
  {
    path: `/${routePaths.downloads}`,
    name: 'downloads',
    props: true,
    component: () => import('@/views/apps/downloads/Downloads.vue'),
    meta: {
      pageTitle: 'downloads',
      breadcrumb: [
        {
          text: 'Downloads',
          to: { name: 'downloads' },
          active: true,
        },
      ],
      resource: 'Profile',
      action: 'read',
    },
  },
  // leads gen ai
  {
    path: '/leads-gen-ai',
    name: 'leads-gen-ai',
    props: true,
    component: () => import('@/views/apps/lead-ai/LeadAI.vue'),
    meta: {
      pageTitle: 'Leads AI',
      breadcrumb: [
        {
          text: 'Leads AI',
          to: { name: 'leads-gen-ai' },
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  {
    path: '/leads-gen-ai/:leadId',
    name: 'leads-gen-ai-detail',
    props: true,
    component: () => import('@/views/apps/lead-ai/LeadAIDetail.vue'),
    meta: {
      pageTitle: 'Leads AI Details',
      breadcrumb: [
        {
          text: 'Leads AI',
          to: { name: 'leads-gen-ai' },
        },
        {
          text: 'Detail',
          dynamic: true,
          leadAIName: '...',
          parse: item => item.leadAIName,
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
]
